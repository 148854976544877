// export default class WpGalleryToList {

//     #gallery = null;
//     #item = null;

//     constructor(
//         galleryDef = {
//             selector: '.wp-block-columns',
//             role: 'list'
//         },
//         itemDef = {
//             selector: '.wp-block-column',
//             role: 'listitem'
//         }) {

//         this.#gallery = galleryDef;
//         this.#item = itemDef;

//         $(document).ready(() => {
//             this.setRolesOnGalleries(this.getGalleries());
//         });
//     }

//     getGalleries() {
//         let galleries = [];
//         const wpGalleries = jQuery(this.#gallery.selector);
//         if (wpGalleries.length > 0) {
//             galleries = jQuery(wpGalleries).toArray()
//         }
//         return galleries;
//     }

//     setRole(wpGallery) {
//         const object = jQuery(wpGallery);
//         object.attr("role", this.#gallery.role);
//         object.find(this.#item.selector).attr("role", this.#item.role);
//     }

//     setRolesOnGalleries(wpGalleries = []) {
//         if (wpGalleries.length > 0) {
//             wpGalleries.forEach(wpGallery => {
//                 this.setRole(wpGallery);
//             });
//         }
//     }
    
// }

// export default class WpGalleryToList {

//     #gallery = null;
//     #item = null;
//     #itemsolo = null;

//     constructor(
//         galleryDef = {
//             selector: '.wp-block-columns',
//             role: 'list'
//         },
//         itemDef = {
//             selector: '.wp-block-columns > .wp-block-column',
//             role: 'listitem'
//         },
//         itemsoloDef = {
//             selector: '.wp-block-column',
//             role: 'listitem'
//         }) 
//         {

//         this.#gallery = galleryDef;
//         this.#item = itemDef;
//         this.#itemsolo = itemsoloDef;

//         $(document).ready(() => {
//             this.setRolesOnGalleries(this.getGalleries());
//         });
//     }

//     getGalleries() {
//         let galleries = [];
//         const wpGalleries = jQuery(this.#gallery.selector);
//         if (wpGalleries.length > 0) {
//             galleries = jQuery(wpGalleries).toArray();
//         }
//         return galleries;
//     }

//     setRole(wpGallery) {
//         const object = jQuery(wpGallery);
//         const group = object.closest('.wp-block-group');
        
//         if (group.length > 0) {
//             group.attr("role", this.#gallery.role);
//         }
        
//         object.find(this.#itemsolo.selector).attr("role", this.#item.role);
//     }

//     setRolesOnGalleries(wpGalleries = []) {
//         if (wpGalleries.length > 0) {
//             wpGalleries.forEach(wpGallery => {
//                 this.setRole(wpGallery);
//             });
//         }
//     }
// }

export default class WpGalleryToList {

    #gallery = null;
    #item = null;
    #itemsolo = null;

    constructor(
        galleryDef = {
            selector: '.wp-block-columns',
            role: 'list'
        },
        itemDef = {
            selector: '.wp-block-columns > .wp-block-column',
            role: 'listitem'
        },
        itemsoloDef = {
            selector: '.wp-block-column',
            role: 'listitem'
        }) 
        {

        this.#gallery = galleryDef;
        this.#item = itemDef;
        this.#itemsolo = itemsoloDef;

        $(document).ready(() => {
            this.setRolesOnGalleries(this.getGalleries());
        });
    }

    getGalleries() {
        let galleries = [];
        const wpGalleries = jQuery(this.#gallery.selector);
        if (wpGalleries.length > 0) {
            galleries = jQuery(wpGalleries).toArray();
        }
        return galleries;
    }

    setRole(wpGallery) {
        const object = jQuery(wpGallery);
        const group = object.closest('.wp-block-group');

        // Classe "gutenberg-list-item" sur un wp-block-columns
        if (object.hasClass('gutenberg-list')) {
            if (object.length > 0) {
                object.attr("role", this.#gallery.role);
            }
            object.find(this.#itemsolo.selector).attr("role", this.#item.role);
        }
        // Classe "gutenberg-list-item" sur un wp-block-group
        if (group.hasClass('gp-gutenberg-list')) {
            if (group.length > 0) {
                group.attr("role", this.#gallery.role);
            }
            group.find(this.#gallery.selector).attr("role", this.#item.role);
        }
        if (group.hasClass('gp-gutenberg-list-item')) {
            if (group.length > 0) {
                group.attr("role", this.#gallery.role);
            }
            object.find(this.#itemsolo.selector).attr("role", this.#itemsolo.role);
        }
    }

    setRolesOnGalleries(wpGalleries = []) {
        if (wpGalleries.length > 0) {
            wpGalleries.forEach(wpGallery => {
                this.setRole(wpGallery);
            });
        }
    }
}

